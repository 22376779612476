/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

p{
  margin: 0;
}

.body-style {

  background-image: url("~src/assets/img/background_mxss.png") !important;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-width: 100vw;
}

.center {
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.row {
  margin: 0;
}

.circle {

  background: white;
  border-radius: 50%;
  padding: 80px;
  position: relative;
}

.icon {
  transition: .5s;
  display: block;
  position: absolute;
  top: 27%;
  left: 27%;
  height: 80px;
  width: 80px;

}

.circle-hide {
  transition: .3s;
  background: white;
  border-radius: 50%;
  padding: 80px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.circle-hide:hover {
  opacity: 1;
}

.show-m {
  color: #C9133C;
  position: absolute;
  top: 45%;
  left: 30%;
  height: 80px;
  width: 80px;
  font-weight: bold;
}

h5 {
  color: white;
}

.white-background {
  background-color: white;
}

.blue-background {
  background-color: #2D519F;
}

.blue-background-op {
  background-color: #2D519F;
  opacity: 0.8;
}

.white-background-op {
  background-color: white;
  opacity: 0.8;
}

.bold {
  font-weight: bold;
}

.contact {
  background-color: #C9133C;
  opacity: 0.8;
  color: white;
}


.btn-contact{
  border-radius: 25px;
  border: 5px solid white;
  padding-left: 5rem;
  padding-right: 5rem;
  font-size: 1rem;
  color: white;

}
.btn-contact:hover{
  background-color: white;
  color: #C9133C;
  font-weight: bold;
}

.txt{
  font-size: 1.5rem !important;
  color: gray;
}
